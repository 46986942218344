/* src/App.css */
.App {
  background-color:#e8f1fc;
  text-align: center;
  max-width: 1920px;
}
body{
  background-color:#e8f1fc;
 
}

input {
  margin: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}
